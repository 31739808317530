<template>
    <el-dialog :append-to-body="true" width="80%" :title="$t('message.product_list') + ' ' + selectedPackage.barcode" :visible.sync="dialogChangeProcess">
      <div v-loading="loadingData">
        <span v-if="selectedPackProds.length == 0" class="font-bold">
          {{ $t('message.there_is_no_products') }}
        </span>
        <template v-else>
          <el-table
            :data="selectedPackProds"
            style="width: 100%">
            <el-table-column
              type="index"
              width="50">
            </el-table-column>
            <el-table-column
              :label="$t('message.name')"
            >
              <template slot-scope="scope">
                {{ scope.row.product ? scope.row.product.name : '' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              :label="$t('message.quantity')"
            >
            </el-table-column>
            <el-table-column
              :label="$t('message.deal')"
            >
              <template slot-scope="scope">
                {{ scope.row.deal ? scope.row.deal.id : '' }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('message.weight')" width="150">
                <template slot-scope="scope">
                    <el-input 
                        :min="0" 
                        class="mm_custom_input"
                        type="number" 
                        size="mini" 
                        v-model="scope.row.weight"
                        :disabled="true"
                    >
                        <template slot="append"></template>
                    </el-input>
                    <el-input
                        type="text"
                        size="mini"
                        disabled
                        :value="compareAndSetMeasureWord(scope.row)"
                    ></el-input>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.item_weight')" width="150">
                <template slot-scope="scope">
                    <el-input 
                        :min="0" 
                        class="mm_custom_input"
                        type="number" 
                        size="mini" 
                        v-model="scope.row.item_weight"
                        @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                    >
                        <template slot="append">{{ $t('message.kg') }}</template>
                    </el-input>
                    <el-input 
                        class="mm_custom_input"
                        :min="0" 
                        type="number" 
                        size="mini" 
                        @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                        v-model="scope.row.item_weight_in_pounds"
                    >
                        <template slot="append">{{ $t('message.lbs') }}</template>
                    </el-input>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.width') + ' x ' + $t('message.height') + ' x ' + $t('message.length')" width="250">
                <template slot-scope="scope">
                    <div class="d-flex mm-input-without-padding">
                        <el-input
                            class="mm_custom_input"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeightAndSizes(scope.row, 'width')"
                            v-model="scope.row.width"
                        >
                        </el-input>
                        <el-input
                            class="mm_custom_input"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeightAndSizes(scope.row, 'height')"
                            v-model="scope.row.height"
                        >
                        </el-input>
                        <el-input
                            class="mm_custom_input"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeightAndSizes(scope.row, 'length')"
                            v-model="scope.row.length"
                        >
                        </el-input>
                        <el-button 
                            v-if="scope.row.type && scope.row.type === 'product'"  
                            style="border-radius: 5px !important; background-color: #F5F7FA;"
                            size="mini" 
                            disabled>
                                {{ $t('message.sm') }}
                        </el-button>
                    </div>
                    <div class="d-flex mm-input-without-padding">
                        <el-input
                            class="mm_custom_input"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                            v-model="scope.row.width_on_inches"
                        >
                        </el-input>
                        <el-input
                            class="mm_custom_input"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                            v-model="scope.row.height_on_inches"
                        >
                        </el-input>
                        <el-input
                            class="mm_custom_input"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                            v-model="scope.row.length_on_inches"
                        >
                        </el-input>
                        <el-button 
                            v-if="scope.row.type && scope.row.type === 'product'"      
                            style="border-radius: 5px !important; background-color: #F5F7FA;"
                            size="mini" 
                            disabled>
                            {{ $t('message.inch') }}
                        </el-button>
                    </div>
                </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div class="text-right mt-5">
        <el-button @click="setProdWeightsInPack" type="primary"> {{ $t('message.save') }} </el-button>
      </div>
    </el-dialog>
</template>
  
<script>
  // @ is an alias to /src
  import { mapGetters, mapActions } from "vuex";
  import product_calculation_functions from "@/utils/mixins/product_calculation_functions";
  
  export default {
    name: "updatePackProdsWeight",
    mixins: [product_calculation_functions],
    props: {
        selectedPack: {
            default: Object
        },
    },
    data() {
      return {
        selectedPackage: {},
        selectedPackProds: [],
        loadingData: false,
        dialogChangeProcess: false,
      };
    },

    watch: {
        selectedPack: {
            handler: async function(newVal, oldVal) {
              if(newVal && newVal.products){
                this.selectedPackage = newVal;
                this.selectedPackProds = JSON.parse(JSON.stringify(this.selectedPack.products));
                this.dialogChangeProcess = true;
              } else if(newVal && newVal.id){
                this.selectedPackage = newVal;
                this.loadingData = true;
                this.dialogChangeProcess = true;
                this.showPackageDealProducts(newVal.id)
                    .then(res => {
                      this.selectedPackProds = JSON.parse(JSON.stringify(this.products));
                      this.loadingData = false;
                    })
                    .catch(err => {
                      this.loadingData = false;
                    });
              }
            },
            deep: true,
            immediate: true
        },
    },
  
    computed: {
      ...mapGetters({
        mode: "MODE",
        products: "packageForProductCirculations/dealProducts",
      }),
    },

    methods: {
      ...mapActions({
        showPackageDealProducts: "packageForProductCirculations/showPackageDealProducts",
      }),
      setProdWeightsInPack() {
        let data = this.selectedPackProds.map(el => {
          return {
            id: el.id,
            weight: el.weight,
            item_weight: el.item_weight,
            height: el.height,
            width: el.width,
            length: el.length,
            quantity: el.quantity,
            product: {
              id: el.product.id, 
              name: el.product.name
            },
            deal: {
              id: el.deal.id, 
            },
          }
        })
        this.$emit('setProdWeightsInPack', data);
        this.dialogChangeProcess = false;
      }
    },
  };
</script>
<style>
  .mm_active{
      color: #E6A23C;
  }
  .mm_process{
      color: #409EFF;
  }
  .mm_success{
      color: #67C23A;
  }
  .link{
      text-decoration: none;
  }
</style>
  
  